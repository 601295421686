<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Organizations ({{organizations.length}})
                <span class="pb-5">
                  <button class="btn btn-success btn-sm" @click="organizationModal = true; organizationMode = 'add'">+ Add Organization</button>
                  <button class="btn btn-dark btn-sm ms-3 text-uppercase" @click="massEmailModal = true">Mass Email</button>
                  <button class="btn btn-primary btn-sm ms-3 text-uppercase" @click="$refs.refInputEl.click()">Upload Organization File</button>
                    <input
                        ref="refInputEl"
                        type="file"
                        name="logo"
                        accept=".xlsx,.xls"
                        hidden
                        @input="uploadOrgFile"
                    >
                </span>
              </span>
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Address</th>
                <th scope="col">Phone</th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col">Created</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="organization in organizations">
                <td>{{ organization.name }}</td>
                <td>{{ organization.address }}</td>
                <td>{{ organization.phone }}</td>
                <td>{{ organization.email }}</td>
                <td><badge :type="getPillByStatus(organization.status)">{{ organization.status }}</badge></td>
                <td>{{ formatDate(organization.created_at) }}</td>
                <td>
                  <button
                      class="btn btn-primary btn-sm"
                      @click="organizationModal = true; organizationMode = 'edit'; populateOrganizationEdit(organization)"
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                      class="btn btn-sm btn-success"
                      @click="detailsModal = true; populateOrganizationEdit(organization)"
                  >
                    Details
                  </button>
                </td>
                <td>
                  <button
                      class="btn btn-sm"
                      :class="organization.integrations?.length? 'btn-secondary' : 'btn-info'"
                      @click="integrationModal = true; populateOrganizationEdit(organization)"
                  >
                    Integration
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Details Modal -->
    <Modal :open="detailsModal" @closed="detailsModal = false" dialog-size="modal-xl">
      <div class="modal-header">
        <h5 class="modal-title m-0">
          Organization Details
          <button class="btn" @click="switchOrganization(organization.id)"></button>
        </h5>
        <button @click="detailsModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div v-if="loading" class="text-center">
            <Spinner spinner-class="mb-1" />
          </div>
          <div v-if="!loading" class="col-md-12">
            <h3 class="text-center">{{ organization.name }}</h3>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div v-if="!loading" class="col-md-12 row">
            <div class="col-md-4 col-lg-3">
              <img class="img-thumbnail bg-dark-subtle" width="190" :src="filesUrl + organization.logo" alt="">
            </div>
            <div class="col-md-8 col-lg-9 row">
              <div class="col-md-6 col-lg-4">
                <h6 class="mt-1 fw-bold">Address</h6>
                <p class="fst-italic">{{ organization.address }}</p>
              </div>
              <div class="col-md-6 col-lg-4">
                <h6 class="mt-1 fw-bold">Phone</h6>
                <p class="fst-italic">{{ organization.phone }}</p>
              </div>
              <div class="col-md-6 col-lg-4">
                <h6 class="mt-1 fw-bold">Email address</h6>
                <p class="fst-italic">{{ organization.email }}</p>
              </div>
              <div class="col-md-6 col-lg-4">
                <h6 class="mt-1 fw-bold">Website</h6>
                <p class="fst-italic">{{ organization.website }}</p>
              </div>
              <div class="col-md-6 col-lg-4">
                <h6 class="mt-1 fw-bold">PIN No.</h6>
                <p class="fst-italic">{{ organization.pin_no }}</p>
              </div>
              <div class="col-md-6 col-lg-4">
                <h6 class="mt-1 fw-bold">NHIF No.</h6>
                <p class="fst-italic">{{ organization.nhif_no }}</p>
              </div>
              <div class="col-md-6 col-lg-4">
                <h6 class="mt-1 fw-bold">NSSF No.</h6>
                <p class="fst-italic">{{ organization.nssf_no }}</p>
              </div>
              <div class="col-md-6 col-lg-4">
                <h6 class="mt-1 fw-bold">Bank name</h6>
                <p class="fst-italic">{{ organization.bank_name }}</p>
              </div>
              <div class="col-md-6 col-lg-4">
                <h6 class="mt-1 fw-bold">Account number</h6>
                <p class="fst-italic">{{ organization.account_no }}</p>
              </div>
              <div class="col-md-6 col-lg-4">
                <h6 class="mt-1 fw-bold">Branch name</h6>
                <p class="fst-italic">{{ organization.branch_name }}</p>
              </div>
              <div class="col-md-6 col-lg-4">
                <h6 class="mt-1 fw-bold">Bank code</h6>
                <p class="fst-italic">{{ organization.bank_code }}</p>
              </div>
              <div class="col-md-6 col-lg-4">
                <h6 class="mt-1 fw-bold">Branch code</h6>
                <p class="fst-italic">{{ organization.branch_code }}</p>
              </div>
            </div>
          </div>

          <hr class="border-1 border-info border-opacity-75 mt-3" />

          <div class="row">
            <h5 class="fw-bold">Files:</h5>
            <div v-for="file in organization.files" :key="file.id" class="col-md-3">
              <Card>
                <div class="text-center">
                  <h6 class="text-dark">{{file.name}}</h6>
                </div>
                <div class="text-center">
                  <button
                      class="btn btn-outline-info btn-sm"
                      @click="viewFile(file.file)"
                  >
                    View
                  </button>
                  <button
                      class="btn btn-outline-danger btn-sm ms-3"
                      @click="detailsModal = false; confirmDialog(
                          'Revoke File',
                          () => revokeFile(file.id),
                          'Do you want to revoke this file?'
                          )"
                  >
                    Revoke
                  </button>
                </div>
              </Card>
            </div>
          </div>

          <hr class="border-1 border-info border-opacity-75 mt-3" />

          <div class="form-group col-md-2">
            <label class="form-label fw-bold h6 mt-3">Deduct PAYE:</label>
            <input type="checkbox" class="form-control form-control-sm form-check-input" :checked="organization.paye" disabled>
          </div>
          <div class="form-group col-md-2">
            <label class="form-label fw-bold h6 mt-3">Deduct NHIF:</label>
            <input type="checkbox" class="form-control form-control-sm form-check-input" :checked="organization.nhif" disabled>
          </div>
          <div class="form-group col-md-2">
            <label class="form-label fw-bold h6 mt-3">Deduct NSSF:</label>
            <input type="checkbox" class="form-control form-control-sm form-check-input" :checked="organization.nssf" disabled>
          </div>
          <div class="form-group col-md-2">
            <label class="form-label fw-bold h6 mt-3">Deduct Housing Levy:</label>
            <input type="checkbox" class="form-control form-control-sm form-check-input" :checked="organization.housing" disabled>
          </div>
          <div class="form-group col-md-2">
            <label class="form-label fw-bold h6 mt-3">Deduct NITA:</label>
            <input type="checkbox" class="form-control form-control-sm form-check-input" :checked="organization.nita" disabled>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark text-uppercase" @click="detailsModal = false">Close</button>
      </div>
    </Modal>
    <!-- Details Modal -->

    <!-- Mass Email Modal -->
    <Modal :open="massEmailModal" @closed="massEmailModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0">Send Mass Email</h5>
        <button @click="massEmailModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="audience" class="form-label fw-bold h5">Send To:</label>
              <select data="" type="text" class="form-select form-select-lg" v-model="massEmail.audience">
                <option value="all" selected>All Clients</option>
                <option value="active">Active Clients</option>
              </select>
            </div>
            <div class="form-group col-md-12">
              <label for="subject" class="form-label fw-bold h5 mt-3">Email Subject:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Subject" v-model="massEmail.subject">
            </div>
            <div class="form-group col-md-12" style="height: 500px !important">
              <label for="message" class="form-label fw-bold h5 mt-3">Message:</label>
              <QuillEditor theme="snow" toolbar="full" content-type="html" v-model:content="massEmail.message" style="height: 400px !important" />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark text-uppercase" @click="massEmailModal = false">Close</button>
        <button type="button" class="btn btn-primary text-uppercase" @click="sendMassEmail">Submit</button>
      </div>
    </Modal>
    <!-- Mass Email Modal -->

    <!-- Integration Modal -->
    <Modal :open="integrationModal" @closed="integrationModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0">Add Payment Integration</h5>
        <button @click="integrationModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <div v-if="organization.integrations?.length" role="alert" class="alert alert-info">
          <div class="content">
            <h5>Available Integrations are:</h5>
            <ul>
              <li v-for="integration in organization.integrations" class="fw-bold">{{ integration.type.toUpperCase() }}</li>
            </ul>
          </div>
        </div>

        <label for="method" class="form-label fw-bold h5 mt-3">Payment Method:</label>
        <select data="" type="text" class="form-select form-select-lg mb-4" v-model="integrationType">
          <option value="mpesa" selected>MPESA</option>
          <option value="bank" disabled>BANK</option>
        </select>
        <h5 v-if="integrationType === 'bank'"><strong>Bank Name:</strong> {{ organization.bank_name }}</h5>
        <h5 v-if="integrationType === 'bank'"><strong>Bank Code:</strong> {{ organization.bank_code }}</h5>
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="key" class="form-label fw-bold h5 mt-3">Consumer Key:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Consumer Key" v-model="integration.consumer_key">
            </div>
            <div class="form-group col-md-12">
              <label for="secret" class="form-label fw-bold h5 mt-3">Consumer Secret:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Consumer Secret" v-model="integration.consumer_secret">
            </div>
            <div v-if="organization.bank_code === '01' && integrationType === 'bank'" class="form-group col-md-12">
              <label for="company_code" class="form-label fw-bold h5 mt-3">Company Code:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Company Code" v-model="integration.meta.company_code">
            </div>
            <div v-if="integrationType === 'mpesa'" class="form-group col-md-12">
              <label for="pass_key" class="form-label fw-bold h5 mt-3">Pass Key:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Pass Key" v-model="integration.meta.pass_key">
            </div>
            <div v-if="integrationType === 'mpesa'" class="form-group col-md-12">
              <label for="short_code" class="form-label fw-bold h5 mt-3">Short Code:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Short Code" v-model="integration.meta.short_code">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark text-uppercase" @click="integrationModal = false">Close</button>
        <button type="button" class="btn btn-primary text-uppercase" @click="updateIntegration()">Submit</button>
      </div>
    </Modal>
    <!-- Integration Modal -->

    <!-- Add Organization Modal -->
    <Modal :open="organizationModal" @closed="organizationModal = false" dialog-size="modal-xl">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="organizationMode === 'add'">Add Organization</h5>
        <h5 class="modal-title m-0" v-else>Edit Organization</h5>
        <button @click="organizationModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Name" v-model="organization.name">
            </div>
            <div class="form-group col-md-8">
              <label for="name" class="form-label fw-bold h5 mt-3">Address:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Address" v-model="organization.address">
            </div>
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Phone:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Phone" v-model="organization.phone">
            </div>
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Email:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Email" v-model="organization.email">
            </div>
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Website:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Website" v-model="organization.website">
            </div>
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">PIN No:</label>
              <input type="text" class="form-control form-control-lg" placeholder="PIN No" v-model="organization.pin_no">
            </div>
            <div class="form-group col-md-3">
              <label for="name" class="form-label fw-bold h5 mt-3">NHIF No:</label>
              <input type="text" class="form-control form-control-lg" placeholder="NHIF No" v-model="organization.nhif_no">
            </div>
            <div class="form-group col-md-3">
              <label for="name" class="form-label fw-bold h5 mt-3">NSSF No:</label>
              <input type="text" class="form-control form-control-lg" placeholder="NSSF No" v-model="organization.nssf_no">
            </div>
            <div v-if="organizationMode === 'edit'" class="form-group col-md-2">
              <label for="name" class="form-label fw-bold h5 mt-3">Status:</label>
              <select data="" type="text" class="form-select form-select-lg" v-model="organization.status">
                <option value="active">Active</option>
                <option value="deactivated">Deactivated</option>
              </select>
            </div>
            <div v-else class="form-group col-md-2">
              <label for="name" class="form-label fw-bold h5 mt-3">Trial Period:</label>
              <input type="number" class="form-control form-control-lg" v-model="organization.trial">
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="row">
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Bank Name:</label>
              <input type="text" class="form-control form-control-lg" list="bankOptions" id="bankName" v-model="organization.bank_name" placeholder="Type to search...">
              <datalist id="bankOptions">
                <option v-for="bank in bankNames.banks" :key="bank" :value="bank"></option>
              </datalist>
            </div>
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Branch Name:</label>
              <input type="text" class="form-control form-control-lg" list="branchOptions" id="branchName" v-model="organization.branch_name" placeholder="Type to search...">
              <datalist id="branchOptions">
                <option v-for="branch in bankBranches" :key="branch" :value="branch"></option>
              </datalist>
            </div>
            <div class="form-group col-md-4">
              <label for="name" class="form-label fw-bold h5 mt-3">Account Number:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Account number" v-model="organization.account_no">
            </div>
          </div>
          <div v-if="organizationMode === 'add'" class="row">
            <hr class="border-1 border-info border-opacity-75 mt-4" />
            <div class="form-group col-md-2">
              <label for="paye" class="form-label fw-bold h6 mt-3">Deduct PAYE:</label>
              <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="organization.paye">
            </div>
            <div class="form-group col-md-2">
              <label for="paye" class="form-label fw-bold h6 mt-3">Deduct NHIF:</label>
              <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="organization.nhif">
            </div>
            <div class="form-group col-md-2">
              <label for="paye" class="form-label fw-bold h6 mt-3">Deduct NSSF:</label>
              <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="organization.nssf">
            </div>
            <div class="form-group col-md-2">
              <label for="paye" class="form-label fw-bold h6 mt-3">Deduct Housing Levy:</label>
              <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="organization.housing">
            </div>
            <div class="form-group col-md-2">
              <label for="paye" class="form-label fw-bold h6 mt-3">Deduct NITA:</label>
              <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="organization.nita">
            </div>
            <hr class="border-1 border-info border-opacity-75 mt-3" />
            <div class="form-group col-md-3">
              <label for="first_name" class="form-label fw-bold h5 mt-3">First Name:</label>
              <input type="text" class="form-control form-control-lg" placeholder="First Name" v-model="organization.user.first_name">
            </div>
            <div class="form-group col-md-3">
              <label for="last_name" class="form-label fw-bold h5 mt-3">Last Name:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Last Name" v-model="organization.user.last_name">
            </div>
            <div class="form-group col-md-3">
              <label for="email" class="form-label fw-bold h5 mt-3">Email:</label>
              <input type="email" class="form-control form-control-lg" placeholder="Email" v-model="organization.user.email">
            </div>
            <div class="form-group col-md-3">
              <label for="phone" class="form-label fw-bold h5 mt-3">Phone:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Phone" v-model="organization.user.phone">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="organizationModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="organizationMode === 'add'" @click="addOrganization">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateOrganization()">Update</button>
      </div>
    </Modal>
    <!-- Add Organization Modal End -->

  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import organizationService from "@/api/organizationService";
import formatDate from "../../utils/formatDate";
import bankService from "@/api/bankService";
import confirmDialog from "@/utils/confirmDialog";
import accountService from "@/api/accountService";

export default {
  name: "AllOrganizations",

  components: {
    QuillEditor
  },

  data(){
    return {
      loading: true,
      organizations: [],
      organizationMode: 'add',
      organizationModal: false,
      organization: {
        paye: true,
        nhif: true,
        nssf: true,
        housing: true,
        nita: true,
        user: {
          role: 'primary',
        },
        trial: 1,
      },
      bankNames: {
        banks: [],
        branches: {}
      },
      bankBranches: [],
      integration: {
        consumer_key: '',
        consumer_secret: '',
        status: 'active',
        meta: {}
      },
      integrationModal: false,
      integrationType: 'mpesa',
      detailsModal: false,
      filesUrl: process.env.VUE_APP_API_IMAGE_URL + 'organization/',
      massEmailModal: false,
      massEmail: {
        audience: 'all',
        subject: '',
        message: '',
      }
    }
  },

  methods: {
    confirmDialog,
    formatDate,
    async getOrganizations() {
      this.organizations = await organizationService.getOrganizations();
      this.loading = false;
    },
    populateOrganizationEdit(organization) {
      this.organization = organization
    },
    async addOrganization() {
      let response = await organizationService.createOrganization(this.organization)
      if (response)
        this.organizationModal = false
        await this.getOrganizations()
    },
    async updateOrganization() {
      let response = await organizationService.updateOrganization(this.organization)
      if (response)
        this.organizationModal = false
        await this.getOrganizations()
    },
    async updateIntegration() {
      this.integration.organization_id = this.organization.id
      this.integration.type = this.integrationType
      let response = await accountService.updateIntegration(this.integration)
      if (response) {
        this.integrationModal = false
        window.location.reload()
      }
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'deactivated':
          return 'danger';
        case 'active':
          return 'success';
      }
    },
    async switchOrganization(id) {
      await organizationService.switchOrganization(id)
    },
    uploadOrgFile : async function(file) {
      const fileReader = new FileReader()
      const { files } = file.target
      let response = false
      if (files && files.length) {
        fileReader.readAsDataURL(files[0])
        fileReader.onload = async () => {
          if (typeof fileReader.result === 'string')
            response = await organizationService.uploadOrgCreationFile({ file: fileReader.result })
          if (response)
            await this.getOrganizations()
        }
      }
    },
    viewFile(file) {
      const fileLocation = this.filesUrl + file
      window.open(fileLocation, '_blank');
    },
    async revokeFile(id) {
      await organizationService.revokeOrganizationFile(id)
    },
    async sendMassEmail() {
      await organizationService.sendMassEmail(this.massEmail)
    }
  },

  created: async function () {
    await this.getOrganizations()
    this.bankNames = await bankService.getBankNames()
  },

  watch: {
    organizationModal: function () {
      if (!this.organizationModal) {
        this.organization = {
          paye: true,
          nhif: true,
          nssf: true,
          housing: true,
          nita: true,
        }
        this.organization.user = {}
        this.organization.package = {}
      }
    },
    'organization.bank_name': function () {
      this.bankBranches = this.bankNames.branches[this.organization.bank_name]
    }
  }
}
</script>

<style scoped>

</style>
