<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Units ({{units.length}})
                <span class="pb-5">
                  <button v-if="profile.role !== 'user'" class="btn btn-success btn-sm" @click="unitModal = true; unitMode = 'add'">+ Add Unit</button>
                </span>
              </span>
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover text-nowrap">
              <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Created</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="unit in units">
                <td>{{ unit.name }}</td>
                <td>{{ unit.description }}</td>
                <td>{{ formatDate(unit.created_at) }}</td>
                <td>
                  <button
                      v-if="profile.role !== 'unit'"
                      class="btn btn-primary btn-sm"
                      @click="unitModal = true; unitMode = 'edit'; populateUnitEdit(unit)"
                  >
                    Edit
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add Unit Modal -->
    <Modal :open="unitModal" @closed="unitModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="unitMode === 'add'">Add Unit</h5>
        <h5 class="modal-title m-0" v-else>Edit Unit</h5>
        <button @click="unitModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Name" v-model="unit.name">
            </div>
            <div class="form-group col-md-6">
              <label for="description" class="form-label fw-bold h5 mt-3">Description: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" placeholder="Description" v-model="unit.description">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="unitModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="unitMode === 'add'" @click="addUnit">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateUnit(unit.id)">Update</button>
      </div>
    </Modal>
    <!-- Add Unit Modal End -->

  </div>
</template>

<script>
import unitService from "@/api/unitService";
import formatDate from "../../utils/formatDate";
import {decrypt} from "@/utils/crypto";

export default {
  name: "Units",

  data(){
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    return {
      profile: {},
      organization: {},
      loading: true,
      units: [],
      unitMode: 'add',
      unitModal: false,
      unit: {
        organization_id: this.organization.id,
        name: '',
        description: '',
      },
    }
  },

  methods: {
    formatDate,
    getUnits: async function () {
      this.units = await unitService.getUnits();
      this.loading = false
    },
    populateUnitEdit(unit) {
      this.unit = unit
    },
    async addUnit() {
      let response = await unitService.createUnit(this.unit)
      if (response) {
        this.unitModal = false
        await this.getUnits()
      }
    },
    async updateUnit(id) {
      let response = await unitService.updateUnit(this.unit, id)
      if (response) {
        this.unitModal = false
        await this.getUnits()
      }
    },
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getUnits()
  },

  watch: {
    unitModal: function () {
      if (!this.unitModal) {
        this.unit = {
          organization_id: this.organization.id,
        }
      }
    },
  }

}
</script>

<style scoped>

</style>
